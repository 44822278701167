// extracted by mini-css-extract-plugin
export var mt0 = "Materials-module--mt-0--1g5Ml";
export var pt0 = "Materials-module--pt-0--1jV9P";
export var mb0 = "Materials-module--mb-0--DbGSf";
export var pb0 = "Materials-module--pb-0--12Eqj";
export var ml0 = "Materials-module--ml-0--sI59M";
export var pl0 = "Materials-module--pl-0--H39y4";
export var mr0 = "Materials-module--mr-0--36_et";
export var pr0 = "Materials-module--pr-0--4N9ji";
export var mt5 = "Materials-module--mt-5--Nj6gZ";
export var pt5 = "Materials-module--pt-5--1Fz0k";
export var mb5 = "Materials-module--mb-5--12dsB";
export var pb5 = "Materials-module--pb-5--nnWBd";
export var ml5 = "Materials-module--ml-5--3IAhl";
export var pl5 = "Materials-module--pl-5--shfFJ";
export var mr5 = "Materials-module--mr-5--3Mu5v";
export var pr5 = "Materials-module--pr-5--3EOV7";
export var mt10 = "Materials-module--mt-10--vmlQY";
export var pt10 = "Materials-module--pt-10--WGXzv";
export var mb10 = "Materials-module--mb-10--155yr";
export var pb10 = "Materials-module--pb-10--3-GWd";
export var ml10 = "Materials-module--ml-10--12Ngu";
export var pl10 = "Materials-module--pl-10--2PPp1";
export var mr10 = "Materials-module--mr-10--3-3un";
export var pr10 = "Materials-module--pr-10--2mRhj";
export var mt15 = "Materials-module--mt-15--1j-47";
export var pt15 = "Materials-module--pt-15--1AgQR";
export var mb15 = "Materials-module--mb-15--1gDOt";
export var pb15 = "Materials-module--pb-15--2te3c";
export var ml15 = "Materials-module--ml-15--2MS4W";
export var pl15 = "Materials-module--pl-15--1YCMP";
export var mr15 = "Materials-module--mr-15--1GC98";
export var pr15 = "Materials-module--pr-15--3WKyy";
export var mt20 = "Materials-module--mt-20--39YB8";
export var pt20 = "Materials-module--pt-20--1wTtR";
export var mb20 = "Materials-module--mb-20--3UyqQ";
export var pb20 = "Materials-module--pb-20--2fbF6";
export var ml20 = "Materials-module--ml-20--1dFKW";
export var pl20 = "Materials-module--pl-20--1MjsA";
export var mr20 = "Materials-module--mr-20--1uES_";
export var pr20 = "Materials-module--pr-20--j7JFp";
export var mt25 = "Materials-module--mt-25--2giGJ";
export var pt25 = "Materials-module--pt-25--3HCkl";
export var mb25 = "Materials-module--mb-25--17RFN";
export var pb25 = "Materials-module--pb-25--1CzIS";
export var ml25 = "Materials-module--ml-25--3i-Jo";
export var pl25 = "Materials-module--pl-25--38qNq";
export var mr25 = "Materials-module--mr-25--W9FGR";
export var pr25 = "Materials-module--pr-25--3ZHZ3";
export var mt30 = "Materials-module--mt-30--3QVML";
export var pt30 = "Materials-module--pt-30--3KRiE";
export var mb30 = "Materials-module--mb-30--3qAs6";
export var pb30 = "Materials-module--pb-30--1Ye_v";
export var ml30 = "Materials-module--ml-30--QcqVE";
export var pl30 = "Materials-module--pl-30--1tnsP";
export var mr30 = "Materials-module--mr-30--1F9F8";
export var pr30 = "Materials-module--pr-30--2SlqB";
export var mt35 = "Materials-module--mt-35--22NBT";
export var pt35 = "Materials-module--pt-35--1FOTB";
export var mb35 = "Materials-module--mb-35--1qHpD";
export var pb35 = "Materials-module--pb-35--3LMg8";
export var ml35 = "Materials-module--ml-35--Cv1Yl";
export var pl35 = "Materials-module--pl-35--81meX";
export var mr35 = "Materials-module--mr-35--2y_wk";
export var pr35 = "Materials-module--pr-35--2tUYY";
export var mt40 = "Materials-module--mt-40--FylPd";
export var pt40 = "Materials-module--pt-40--2cgX2";
export var mb40 = "Materials-module--mb-40--3uZwZ";
export var pb40 = "Materials-module--pb-40--36TTu";
export var ml40 = "Materials-module--ml-40--2762m";
export var pl40 = "Materials-module--pl-40--XdR8I";
export var mr40 = "Materials-module--mr-40--3TlX3";
export var pr40 = "Materials-module--pr-40--1Ka-G";
export var mt45 = "Materials-module--mt-45--2qV1P";
export var pt45 = "Materials-module--pt-45--1SBg0";
export var mb45 = "Materials-module--mb-45--30i0f";
export var pb45 = "Materials-module--pb-45--XMxli";
export var ml45 = "Materials-module--ml-45--2jU5Z";
export var pl45 = "Materials-module--pl-45--9Picd";
export var mr45 = "Materials-module--mr-45--3FPbc";
export var pr45 = "Materials-module--pr-45--21Wn3";
export var mt50 = "Materials-module--mt-50--2DEtL";
export var pt50 = "Materials-module--pt-50--1u1D7";
export var mb50 = "Materials-module--mb-50--3Knyj";
export var pb50 = "Materials-module--pb-50--37M9U";
export var ml50 = "Materials-module--ml-50--w6qK2";
export var pl50 = "Materials-module--pl-50--2ZkoM";
export var mr50 = "Materials-module--mr-50--jz1Eq";
export var pr50 = "Materials-module--pr-50--1UZ4K";
export var mt75 = "Materials-module--mt-75--16sPk";
export var pt75 = "Materials-module--pt-75--3x4nT";
export var mb75 = "Materials-module--mb-75--mzfoZ";
export var pb75 = "Materials-module--pb-75--QUxb1";
export var ml75 = "Materials-module--ml-75--2QB7u";
export var pl75 = "Materials-module--pl-75--1qwIo";
export var mr75 = "Materials-module--mr-75--16bny";
export var pr75 = "Materials-module--pr-75--1TvgK";
export var mt100 = "Materials-module--mt-100--1FFTp";
export var pt100 = "Materials-module--pt-100--1Bt89";
export var mb100 = "Materials-module--mb-100--3MB_G";
export var pb100 = "Materials-module--pb-100--37FAX";
export var ml100 = "Materials-module--ml-100--1az7G";
export var pl100 = "Materials-module--pl-100--1c1T5";
export var mr100 = "Materials-module--mr-100--1Q9oB";
export var pr100 = "Materials-module--pr-100--1Owu5";
export var zIndex1 = "Materials-module--z-index-1--a5jXB";
export var zIndex2 = "Materials-module--z-index-2--2fBfm";
export var zIndex3 = "Materials-module--z-index-3--3BkHj";
export var zIndex4 = "Materials-module--z-index-4--3DNWr";
export var zIndex5 = "Materials-module--z-index-5--3TBNF";
export var zIndex6 = "Materials-module--z-index-6--2YRLz";
export var zIndex7 = "Materials-module--z-index-7--3OTEf";
export var zIndex8 = "Materials-module--z-index-8--3E4XR";
export var zIndex9 = "Materials-module--z-index-9--3dZL6";
export var zIndex10 = "Materials-module--z-index-10--3E8Yz";
export var zIndex20 = "Materials-module--z-index-20--38jXA";
export var zIndex30 = "Materials-module--z-index-30--2Gv0Z";
export var zIndex40 = "Materials-module--z-index-40--P3ZSd";
export var zIndex50 = "Materials-module--z-index-50--1psDE";
export var zIndex60 = "Materials-module--z-index-60--2WJGS";
export var zIndex70 = "Materials-module--z-index-70--1fYfi";
export var zIndex80 = "Materials-module--z-index-80--39YbK";
export var zIndex90 = "Materials-module--z-index-90--1LGXC";
export var zIndex100 = "Materials-module--z-index-100--2Wpjo";
export var link = "Materials-module--link--wjvra";
export var uppercase = "Materials-module--uppercase--eiLMy";
export var more = "Materials-module--more--3g4BD";
export var button = "Materials-module--button--1cGBi";
export var send = "Materials-module--send--195x6";
export var sm = "Materials-module--sm--o3BaA";
export var materialsWrap = "Materials-module--materialsWrap--2Lf0Q";
export var title = "Materials-module--title--1p_vv";
export var materialsBoth = "Materials-module--materialsBoth--2jnUi";
export var materials = "Materials-module--materials--3reF7";
export var materialList = "Materials-module--materialList--Qf-vg";
export var listItem = "Materials-module--listItem--fMUm2";
export var materialButton = "Materials-module--materialButton--Are0e";
export var active = "Materials-module--active--WFMA6";
export var bgc = "Materials-module--bgc--1PoPH";
export var materialSample = "Materials-module--materialSample--2--Er";